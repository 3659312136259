<template>
  <div>
    <van-tabbar v-model="active" active-color="#9b1f37" inactive-color="#646464">
      <van-tabbar-item to="/home">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src=@/assets/images/icon_home_HL.png />
          <img v-else src=@/assets/images/icon_home.png />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/dream">
        <span>经典解梦</span>
        <template #icon="props">
          <img v-if="props.active" src=@/assets/images/icon_dream_HL.png />
          <img v-else src=@/assets/images/icon_dream.png />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/onlive">
        <span>开奖直播</span>
        <template #icon="props">
          <img v-if="props.active" src=@/assets/images/icon_onlive_HL.png />
          <img v-else src=@/assets/images/icon_onlive.png />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/me">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src=@/assets/images/icon_me_HL.png />
          <img v-else src=@/assets/images/icon_me.png />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  computed: {
    active: {
      get() {
        return this.$store.state.common.footerActive
      },
      set(value) {
        this.$store.commit('common/updateState', [{ name: ['footerActive'], value: value }])
      }
    },
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
img{
  width: 0.533rem;
  height: 0.533rem;
}
// @import '@/assets/css/public.css';
</style>